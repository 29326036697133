
  import { Component, Ref, Vue } from "vue-property-decorator";
  import {
    ProjectMonthItemDto, InternalProjectDetailDto, InternalProjectPhasesItemDto,ProjectPhaseStatus,WorkflowOfProjectByInternal
  } from "@/api/appService";
  import api from "@/api";

  @Component({
    name: "internalProjectPhasesIndex",
    components: {
    },
    filters: {
      formatStatus(status: string) {
        switch (status) {
          case ProjectPhaseStatus.Ongoing: {
            return '进行中'
          }
          case ProjectPhaseStatus.Complete: {
            return '已完成'
          }
          case ProjectPhaseStatus.DelayComplete: {
            return '延期完成'
          }
          case ProjectPhaseStatus.NoTask: {
            return '无任务'
          }
          default:
            return '未开始'
        }
      },
      getStatue(status: string) {
        switch (status) {
          case 'Ongoing': {
            return 'on-going'
          }
          case 'Complete': {
            return 'complete'
          }
          case 'DelayComplete': {
            return 'delay-complete'
          }
          case 'NoTask': {
            return 'no-task'
          }
        }
      },
      formatProjectType(types: string, projectTypes: any[]) {
        if (types) {
          let typeArray = types.split(',');
          let result = '';
          let first = false;
          if (typeArray && typeArray.length > 0 && projectTypes.length > 0) {
            typeArray.map(item => {
              projectTypes.map(it => {
                if (it.id == item) {
                  if (!first) {
                    result = it.displayName + ',';
                    first = true;
                  } else {
                    result += it.displayName
                  }
                }
              })
            })
          }
          return result;
        }
      }
    },
  })
  export default class InternalProjectPhasesIndex extends Vue {
    dataId?: number;
    detail: InternalProjectDetailDto = {};
    type: string = "internal";
    monthNodes: ProjectMonthItemDto[] = [];
    projectNodes: InternalProjectPhasesItemDto[] = [];
    needFirst: boolean = false;
    needClose: boolean = false;
    firstData: InternalProjectPhasesItemDto = {};
    closeData: InternalProjectPhasesItemDto = {};
    dialogVisible: boolean = false;
    dialogTitle: string = '';
    projectTypes: any = [];

    pageProject:number = 1;
    pageMonth:number = 1;
    activeName:string =  "all";
    activeMonthName:string = "allMonth";
    projectAttachmentNodes:any = [];
    monthAttachmentNodes:any = [];
    listProjectData:any = {
      loading: false,
      page: 1,
      list: [],
      pageSize: 10,
      totalCount: 0,
      queryForm: {
        skipCount: 0,
        maxResultCount: 10,
        sorting: "id descending",
        stage: null,
        projectId: "",
      }
    }
    listMonthData:any = {
      loading: false,
      page: 1,
      list: [],
      pageSize: 10,
      totalCount: 0,
      queryForm: {
        skipCount: 0,
        maxResultCount: 10,
        sorting: "id descending",
        projectId: "",
        year: null,
        month: null
      }
    }
    listActivityData:any = {
      queryForm: {
        date: undefined
      }
    }

    created() {
      if (this.$route.params.id) {
        this.dataId = Number(this.$route.params.id);
        this.fetchDetail();
        this.getProjectMonthList();
        this.getProjectPhasesList();

        this.getProjectAttachmentListByPhase(1)
        this.getProjectAttachmentListByMonth(1)

        this.fetchDataDictionary();
      }
    }

    fetchDataDictionary() {
      api.dataDictionary.getDataDictionaryListByKey({key: 'ProjectType',maxResultCount:1000})
        .then((res:any)=>{
          this.projectTypes = res.items
        })
    }

    // 获取详情
    async fetchDetail() {
      await api.internalProject
        .getDetailByProjectId({ projectId: this.dataId })
        .then(res => {
          this.detail = { ...res };
        });
    }

    async getProjectMonthList() {
      this.monthAttachmentNodes.push({
        name: '全部',
        stage: 'allMonth'
      })
      await api.internalProjectPhase
        .getProjectMonthList({ projectId: this.dataId })
        .then(res => {
          if (res) {
            this.monthNodes = res;
            res.map((item, index) => {
              index++
              let year = String(item.year) + index
              this.monthAttachmentNodes.push({
                name: item.year + '年' + item.month + '月',
                stage: year
              })
            })
          }
        });
    }

    async getProjectPhasesList() {
      this.projectAttachmentNodes.push({
        name: '全部',
        stage: 'all'
      })
      await api.internalProjectPhase
        .getProjectPhasesList({ projectId: this.dataId })
        .then(res => {
          if (res) {
            this.projectNodes = { ...res };
            res.map(item => {
              switch (item.stage) {
                case WorkflowOfProjectByInternal.ProjectApproval:
                  this.needFirst = true;
                  this.firstData = item
                  break
                case WorkflowOfProjectByInternal.Closed:
                  this.needClose = true
                  this.closeData = item
                  break
              }
              this.projectAttachmentNodes.push({
                name: item.name,
                stage: item.stage
              })
            })
          }
        });
    }

    handleProjectTabClick(tab:any, event:any) {
      if (tab.name && tab.name != 'all') {
        this.listProjectData.queryForm.stage = tab.name
      } else {
        this.listProjectData.queryForm.stage = null
      }
      this.listProjectData.page = 1
      this.getProjectAttachmentListByPhase(1)
    }

    getProjectAttachmentListByPhase(page: number) {
      this.listProjectData.loading = true;
      if (!page) page = 1
      this.listProjectData.page = page;
      this.listProjectData.queryForm.maxResultCount = this.listProjectData.pageSize;
      this.listProjectData.queryForm.skipCount = (this.listProjectData.page-1) * this.listProjectData.pageSize;
      this.listProjectData.queryForm.projectId = this.dataId;

      api.internalProjectPhase.getProjectAttachmentListByPhase(this.listProjectData.queryForm).then(res => {
        this.listProjectData.list = res.items
        this.listProjectData.loading = false
        this.listProjectData.totalCount = res.totalCount
      })
    }

    handleProjectSizeChange(e:any) {
      //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
      // this.clearHistorySearchParams()
      this.listProjectData.pageSize = e;
      this.getProjectAttachmentListByPhase(1);
    }
    // 切换切面,在watch中监听 page
    currentProjectChange(e:any) {
      this.listProjectData.page = this.pageProject = e;
      this.getProjectAttachmentListByPhase(e);
    }

    jumpItem(url: string) {
      this.$router.push({
        path: url,
        query: {
          from: 'phases'
        }
      })
    }

    handleMonthTabClick(tab:any, event:any) {
      if (tab.name && tab.name != 'allMonth') {
        let index = tab.label.indexOf('年')
        let month = '';
        month = tab.label.substring(index + 1, tab.label.length - 1)
        this.listMonthData.queryForm.month = month;
        this.listMonthData.queryForm.year = Number(tab.name.substring(0, 4))
      } else {
        this.listMonthData.queryForm.month = null
        this.listMonthData.queryForm.year = null
      }
      this.listMonthData.page = 1
      this.getProjectAttachmentListByMonth(1)
    }

    getProjectAttachmentListByMonth(page: number) {
      this.listMonthData.loading = true;
      if (!page) page = 1
      if (!page) page = 1
      this.listMonthData.page = page;
      this.listMonthData.queryForm.maxResultCount = this.listMonthData.pageSize;
      this.listMonthData.queryForm.skipCount = (this.listMonthData.page-1) * this.listMonthData.pageSize;
      this.listMonthData.queryForm.projectId = this.dataId;

      api.internalProjectPhase.getProjectAttachmentListByMonth(this.listMonthData.queryForm).then(res => {
        this.listMonthData.list = res.items
        this.listMonthData.loading = false
        this.listMonthData.totalCount = res.totalCount
      })
    }

    handleMonthSizeChange(e:any) {
      this.listMonthData.pageSize = e;
      this.getProjectAttachmentListByPhase(1);
    }
    // 切换切面,在watch中监听 page
    currentMonthChange(e:any) {
      this.listMonthData.page = this.pageMonth = e;
      this.getProjectAttachmentListByMonth(e)
    }

    getActivityList(year: string, month: string) {
      this.dialogVisible = true
      if (Number(month) < 10) {
        month = '0' + String(month)
      }
      this.listActivityData.queryForm.date= year + '-' + month;
      this.dialogTitle = year + '-' + month + '月活动计划与总结'
    }

    jumpMonthPlan() {
      this.$router.push({
        path: '/project/monthlyPlan',
        query: {
          projectId: String(this.dataId),
          date: this.listActivityData.queryForm.date
        }
      })
    }
    jumpMonthSummary() {
      this.$router.push({
        path: '/project/internalMonthlySummarize',
        query: {
          projectId: String(this.dataId),
          date: this.listActivityData.queryForm.date
        }
      })
    }

    // 返回
    private cancel() {
      this.$router.back();
    }
  }
